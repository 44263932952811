import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { UserCompany } from '@app/models/user.company.model';

export const mockCompany: UserCompany = {
  title: 'default',
  value: 'default'
};

@Injectable({
  providedIn: 'root'
})
export class CompanyStoreService {
  private _currentCompanyId$ = new BehaviorSubject<string>(null);
  private _currentCompany$ = new BehaviorSubject<UserCompany>(mockCompany);

  constructor() {}

  public getCompanyFromSideNav(): Observable<UserCompany> {
    return this._currentCompany$.asObservable().pipe(
      filter((values) => values !== null && values.value !== 'default'),
      distinctUntilChanged((prev, curr) => prev.value === curr.value),
      map((values) => values)
    );
  }

  public getUserSelectedCompanyId(): Observable<string> {
    return this._currentCompanyId$.asObservable().pipe(
      distinctUntilChanged(),
      filter((id) => !!id)
    );
  }

  public getUserSelectedCompanyIdVal(): string {
    return this._currentCompanyId$.getValue();
  }

  setCompany(incomingCompany: UserCompany): void {
    const companyId = incomingCompany?.value;

    this._currentCompany$.next(incomingCompany);
    this._currentCompanyId$.next(companyId);
  }
}
