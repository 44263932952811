export interface ZuiDropdownOptions {
  title: string;
  value: string;
}

export enum SeverityEnum {
  CRITICAL = '1',
  MINOR = '2'
}

export interface FilterLoading {
  loading: boolean;
}
