import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app/models';
import { MyAlertAPIResponse, UnreadMyAlerts } from '@app/models/my-alerts.model';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyAlertsService {
  constructor(public httpClient: HttpClient) {}

  public handleAlertsApi = (key: string, value: string | any) => {
    let appendQueryParams = undefined;
    switch (key) {
      case 'assets':
        appendQueryParams = `source_event.context.entity_ids.asset_id:${value}`;
        break;
      case 'divisions':
        appendQueryParams = `source_event.context.entity_ids.division_id:${value}`;
        break;
      case 'severity':
        appendQueryParams = `source_event.data.severity:${value}`;
        break;
      case 'company':
        appendQueryParams = `source_event.context.entity_ids.company_id:${value}`;
        break;
      default:
        appendQueryParams = undefined;
        break;
    }
    return appendQueryParams;
  };

  public getMyAlerts$(
    filters?: any,
    selectedCompany?: Company,
    params?: { [key: string]: string | number | boolean }
  ): Observable<MyAlertAPIResponse> {
    if (selectedCompany === null) {
      return;
    }

    const filteringObject = [];

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        value && filteringObject.push(this.handleAlertsApi(key, value));
      }
    }

    if (selectedCompany) {
      filteringObject.push(this.handleAlertsApi('company', selectedCompany.value));
    }

    const queryParams = !params ? '' : `&${new HttpParams({ fromObject: params }).toString()}`;
    const qString = encodeURIComponent(filteringObject.join('+'));
    const seletedUri = `${env.apiBase}${env.apiApigeePrefix}/v1/alerts?q=${qString}${queryParams}`;

    return this.httpClient.get<MyAlertAPIResponse>(seletedUri);
  }

  public getUnreadMyAlerts$(selectedCompany: Company, reset = false): Observable<UnreadMyAlerts> {
    if (selectedCompany === null) {
      return;
    }

    const qString = `context.entity_ids.company_id:${selectedCompany.value}`;
    const queryParams = `&reset=${reset}`;
    const uri = `${env.apiBase}${env.apiApigeePrefix}/v1/alerts/alert-count?q=${qString}${queryParams}`;

    return this.httpClient.get<UnreadMyAlerts>(uri);
  }
}
