import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { Company } from '@models/company.model';
import { AlertsAPIResponse } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ActivityAlertsService {
  constructor(public httpClient: HttpClient) {}
  public selectedDivisionUrl = [];
  public handleAlertsApi = (key, value) => {
    let appendQueryParams = undefined;
    switch (key) {
      case 'assets':
        appendQueryParams = `context.entity_ids.asset_id:${value}`;
        break;
      case 'divisions':
        appendQueryParams = `context.entity_ids.division_id:${value}`;
        break;
      case 'severity':
        appendQueryParams = `data.severity:${value}`;
        break;
      default:
        appendQueryParams = undefined;
        break;
    }
    return appendQueryParams;
  };

  public getAlerts$(eventData?, selectedCompany?: Company): Observable<AlertsAPIResponse> {
    if (selectedCompany === null) {
      return;
    }
    let selectedUri = undefined;
    const selectedDivisionUrl = [];
    if (eventData) {
      for (const [key, value] of Object.entries(eventData)) {
        value && selectedDivisionUrl.push(this.handleAlertsApi(key, value));
      }
    }
    let updatedQueryParam = undefined;
    if (selectedCompany) {
      updatedQueryParam = selectedDivisionUrl.push(`context.entity_ids.company_id:${selectedCompany.value}`);
    }

    if (selectedDivisionUrl.length > 0) {
      updatedQueryParam = encodeURIComponent(selectedDivisionUrl.join('+'));
    }

    selectedUri = `${env.apiBase}${env.apiApigeePrefix}/v1/events/activity-feed?q=${updatedQueryParam}`;
    return this.httpClient.get<AlertsAPIResponse>(selectedUri);
  }

  public getScrollAlerts$(from, to, eventData?, selectedCompany?: Company): Observable<AlertsAPIResponse> {
    let selectedUri = undefined;
    const selectedDivisionUrl = [];
    if (eventData) {
      for (const [key, value] of Object.entries(eventData)) {
        value && selectedDivisionUrl.push(this.handleAlertsApi(key, value));
      }
    }
    let updatedQueryParam = undefined;

    if (selectedCompany) {
      updatedQueryParam = selectedDivisionUrl.push(`context.entity_ids.company_id:${selectedCompany.value}`);
    }
    if (selectedDivisionUrl.length > 0) {
      const updatedQueryParam = encodeURIComponent(selectedDivisionUrl.join('+'));
      selectedUri = `${env.apiBase}${env.apiApigeePrefix}/v1/events/activity-feed?q=${updatedQueryParam}&from_item=${from}&to_item=${to}`;
    } else {
      selectedUri = `${env.apiBase}${env.apiApigeePrefix}/v1/events/activity-feed?q=${updatedQueryParam}&from_item=${from}&to_item=${to}`;
    }
    return this.httpClient.get<AlertsAPIResponse>(selectedUri);
  }
}
