<div class="container u-margin__top--medium" [ngClass]="{ 'hide-horizontal-scroll': device.mediaObserver.isActive('lt-md') }">
  <ng-container *ngIf="!device.mediaObserver.isActive('lt-md')">
    <mat-card class="u-margin__bottom--medium">
      <ng-container *ngTemplateOutlet="zuiFiltersBar"></ng-container>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="device.mediaObserver.isActive('lt-md')">
    <ng-container *ngTemplateOutlet="zuiFiltersBar"></ng-container>
  </ng-container>
</div>

<ng-template #zuiFiltersBar>
  <zui-filters-bar
    [filterList]="filterList"
    [filterFormGroup]="filtersBarService.filtersForm"
    [companyId]="companyId$ | async"
    [isUseResetEmitter]="true"
    (OnFilterChanged)="receiveFilterSelection($event)"
    (onResetFilterEmit)="onResetFilterChange()"
  >
  </zui-filters-bar>
</ng-template>
