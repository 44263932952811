<div class="container">
  <div class="flex flex-row justify-between items-center u-margin__top--large">
    <h1 class="margin-bottom__none">Alerts</h1>
    <div class="alert-buttons-container">
      <button
        *ngIf="isManageAlertsButtonDisplayed$ | async"
        mat-stroked-button
        color="accent"
        type="button"
        (click)="redirectToAlertsManagement()"
      >
        <mat-icon>settings</mat-icon>
        <span class="hide-on-mobile u-margin__left--extra-tiny">Manage company alerts</span>
      </button>
      <button mat-stroked-button color="accent" type="button" (click)="redirectToMyAlerts()">
        <mat-icon>tune</mat-icon>
        <span class="hide-on-mobile u-margin__left--extra-tiny">My alerts settings</span>
      </button>
    </div>
  </div>

  <span *ngIf="allTabsLoaded$ | withLoading | async as allTabsLoaded">
    <ng-template [ngIf]="allTabsLoaded.value">
      <mat-tab-group *ngIf="allTabsLoaded$ | async as allTabsLoaded" class="tab-group" (selectedTabChange)="selectedTabValue($event)">
        <mat-tab label="My Alerts" *ngIf="isMyAlertsTabVisible">
          <span *ngIf="{ list: allMyAlert$ | async } as alerts">
            <ng-template mat-tab-label>
              <span
                [matBadge]="(unreadMyAlerts$ | async)?.newAlertCount"
                [matBadgeHidden]="isHideUnreadMyAlerts"
                matBadgeOverlap="false"
                matBadgeColor="warn"
                matBadgeSize="medium"
                class="mat-tab-label-style"
                >My Alerts</span
              >
            </ng-template>

            <span *ngIf="!isClearingFilters">
              <ng-container *ngTemplateOutlet="filters"></ng-container>
            </span>
            <div *ngIf="alerts.list && alerts.list.length === 0" class="text-center u-text--gray">
              <div class="no-activity__headline">No active alerts</div>
              <div class="no-activity__subtext u-margin__bottom--large">
                You either haven't received an alert yet, or are not subscribed to any alerts.
              </div>
              <button *ngIf="isAdmin$ | async" mat-flat-button type="button" color="accent" (click)="redirectToAddSubscription()">
                <mat-icon class="manage-alerts-button__icon">add</mat-icon>
                <span class="u-margin__left--extra-tiny">Create an alert</span>
              </button>
            </div>
            <app-skeleton-loader *ngIf="!alerts.list"></app-skeleton-loader>
            <div
              *ngIf="alerts.list"
              class="scroll"
              infinite-scroll
              [infiniteScrollDistance]="1"
              [infiniteScrollThrottle]="250"
              (scrolled)="onMyAlertScroll()"
              [scrollWindow]="false"
              [infiniteScrollDisabled]="isEndOfMyAlertStream"
              id="scrollToTop"
            >
              <mat-list role="list" *ngFor="let alert of alerts.list; trackBy: trackByFn">
                <app-basic-alert [companyId]="companyId" [alertData]="translateMyAlertToAlert(alert)" [viewType]="alertType"> </app-basic-alert>
              </mat-list>
              <app-skeleton-loader
                *ngIf="!isEndOfMyAlertStream && alerts.list && alerts.list.length >= NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD"
              ></app-skeleton-loader>
              <div
                *ngIf="isEndOfMyAlertStream && !(alerts.list && alerts.list.length < NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD)"
                class="text-center text-color"
              >
                No more activity to display
              </div>
            </div>
          </span>
        </mat-tab>
        <mat-tab label="All Alerts">
          <span *ngIf="{ list: allAlerts$ | async } as alerts">
            <ng-template mat-tab-label>
              <span class="mat-tab-label-style">All Activity</span>
            </ng-template>

            <span *ngIf="!isClearingFilters">
              <ng-container *ngTemplateOutlet="filters"></ng-container>
            </span>

            <div *ngIf="alerts.list && alerts.list.length === 0" class="text-center u-text--gray">
              <div class="no-activity__headline">No activity</div>
              <div class="no-activity__subtext">You're all caught up!</div>
            </div>
            <app-skeleton-loader *ngIf="!alerts.list"></app-skeleton-loader>
            <div
              *ngIf="alerts.list"
              class="scroll"
              infinite-scroll
              [infiniteScrollDistance]="1"
              [infiniteScrollThrottle]="250"
              (scrolled)="onScroll()"
              [scrollWindow]="false"
              [infiniteScrollDisabled]="isEndOfAlertsStream"
              id="scrollToTop"
            >
              <mat-list role="list" *ngFor="let alert of alerts.list; trackBy: trackByFn">
                <app-basic-alert [companyId]="companyId" [alertData]="alert" [viewType]="feedType"> </app-basic-alert>
              </mat-list>
              <app-skeleton-loader *ngIf="!isEndOfAlertsStream"></app-skeleton-loader>
              <div *ngIf="isEndOfAlertsStream && !(alerts.list && alerts.list.length === 0)" class="text-center text-color">
                No more activity to display
              </div>
            </div>
          </span>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
    <ng-template [ngIf]="allTabsLoaded.error">
      <div>Error loading page. Please refresh to try again.</div>
    </ng-template>
    <ng-template [ngIf]="allTabsLoaded.loading">
      <div class="spinner-container" aria-labelledby="tab-loading-spinner"><zui-spinner diameter="150"></zui-spinner></div>
    </ng-template>
  </span>
</div>

<ng-template #filters>
  <ng-container *ngIf="device.mediaObserver.isActive('lt-md'); else desktopView">
    <app-mobile-drawer>
      <app-filter-tags (selectedFilters)="onGetSelectedFilters($event)"></app-filter-tags>
    </app-mobile-drawer>
  </ng-container>

  <ng-template #desktopView>
    <app-filter-tags (selectedFilters)="onGetSelectedFilters($event)"></app-filter-tags>
  </ng-template>
</ng-template>
