import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ReverseGeocoderResponse } from '@models/reverse-geocoder-response.model';

@Injectable({
  providedIn: 'root'
})
export class ReverseGeocoderService {
  url = `${env.reverseGeocoderApiBase}/geocoder/api/v1/reverse`;
  constructor(private http: HttpClient) {}

  getReverseGeocode(latitude: number, longitude: number): Observable<ReverseGeocoderResponse> {
    const params = { latitude, longitude, 'ngsw-bypass': true };
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get(this.url, { params: httpParams }) as Observable<ReverseGeocoderResponse>;
  }
}
