import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SeverityEnum } from '@app/models';
import { DropdownOptionsObject, FILTER_TYPE, SearchableDropdownModel, ZuiAssetFilterModel, ZuiDateFilterModel } from '@zonar-ui/filter';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiltersBarService {
  filtersForm: FormGroup = this.fb.group({});

  constructor(private fb: FormBuilder) {}

  getFilterList(): (ZuiDateFilterModel | SearchableDropdownModel | ZuiAssetFilterModel)[] {
    const commonDropdownProps = {
      isMultiple: true,
      blueCheckmarks: true,
      inputParams: [],
      enableAllOptions: true
    };

    const dropdowns: (ZuiDateFilterModel | SearchableDropdownModel | ZuiAssetFilterModel)[] = [
      {
        type: FILTER_TYPE.ASSET,
        options: {
          label: 'Asset',
          selectedAssetsInput: [],
          paramName: ['division_id', 'asset_id']
        }
      } as ZuiAssetFilterModel,
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Severity',
          data: this._getSeverityOptions(),
          fgControlName: 'severity',
          paramName: 'severity',
          valueType: 'string',
          defaultValue: null,
          ...commonDropdownProps,
          svgOptions: {
            svgs: ['assets/images/critical.svg', 'assets/images/warning.svg'],
            currentIndex: 0,
            width: '14px',
            height: '14px'
          }
        }
      } as SearchableDropdownModel
    ];

    return dropdowns;
  }

  private _getSeverityOptions(): Observable<DropdownOptionsObject[]> {
    const options: DropdownOptionsObject[] = [
      {
        title: 'Critical',
        value: SeverityEnum.CRITICAL
      },
      {
        title: 'Minor',
        value: SeverityEnum.MINOR
      }
    ];
    return of(options);
  }
}
