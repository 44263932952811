import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CompanyStoreService } from '@app/services/company-store.service';
import { DeviceDetectionService } from '@app/services/device-detection.service';
import { FiltersBarService } from '@app/services/filters-bar.service';
import { ActivityAlertsService } from '@services/activity-alerts.service';
import { ReplaySubject } from 'rxjs';
import { Company } from 'src/app/models/company.model';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss']
})
export class FilterTagsComponent implements OnInit, OnDestroy {
  constructor(
    public fb: FormBuilder,
    public activityAlertService: ActivityAlertsService,
    public device: DeviceDetectionService,
    public filtersBarService: FiltersBarService,
    private _companyService: CompanyStoreService
  ) {}
  public divisions = new FormControl();
  @Output() postSelectedDivision = new EventEmitter();
  @Output() selectedFilters = new EventEmitter();

  divisionList$ = new ReplaySubject<any[]>(1);
  filterList;

  companyId$ = this._companyService.getUserSelectedCompanyId();

  selectedCompany: Company;
  public divisionList = [];
  isInitialPageLoad = true;

  filtersValueStateObject = {
    assets: '',
    divisions: '',
    severity: ''
  };

  receiveFilterSelection($event) {
    if ('asset_filter' in $event) {
      this.filtersValueStateObject.assets = $event.asset_filter.selected.assetIds.length ? $event.asset_filter.selected.assetIds : '';
      this.filtersValueStateObject.divisions = $event.asset_filter.selected.divisionIds.length
        ? $event.asset_filter.selected.divisionIds
        : '';
    } else if ('severity' in $event) {
      this.filtersValueStateObject.severity = $event.severity.selected;
    }
    this.selectedFilters.emit(this.filtersValueStateObject);
  }

  onResetFilterChange() {
    this.resetFilters();
  }

  ngOnInit(): void {
    this.filterList = this.filtersBarService.getFilterList();
  }

  resetFilters() {
    this.selectedFilters.emit({
      assets: '',
      divisions: '',
      severity: ''
    });
  }

  ngOnDestroy() {
    this.resetFilters();
  }
}
